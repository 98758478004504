import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/icon-download-slim.svg'


const _withScopeId = n => (_pushScopeId("data-v-c705fb90"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "document-item-wrapper",
    onClick: $setup.openFile
  }, [
    _createElementVNode("div", {
      class: _normalizeClass($setup.props.classes.container)
    }, [
      _createElementVNode("img", {
        class: _normalizeClass($setup.props.classes.preview),
        src: $setup.previewIcon($setup.props.link, $setup.props.name)
      }, null, 10, _hoisted_1),
      _renderSlot(_ctx.$slots, "content", {}, undefined, true)
    ], 2),
    _createElementVNode("img", {
      class: "document-item--download",
      src: _imports_0,
      onClick: _withModifiers($setup.download, ["stop","prevent"])
    }, null, 8, _hoisted_2),
    _createVNode($setup["ImagePreviewModal"], {
      showImg: $setup.showImg,
      onClose: _cache[0] || (_cache[0] = ($event: any) => ($setup.showImg = undefined))
    }, null, 8, ["showImg"])
  ]))
}